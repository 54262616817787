import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import CollapsibleBox from '../../components/collapsibleBox';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Section from '../../components/section';
import SEO from '../../components/seo';
import SharingButtons from '../../components/sharingButtons';
import SafeLink from '../../components/safeLink';

const ProjectsAction4Inclusion = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle = '#Action4Inclusion';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:40vh;`]}
      />
      <Section>
        <h2>Overview</h2>
        <LeadText>
          We are on a mission to make education accessible to all, regardless of financial background. Our aim is to help students who are struggling with debt to continue their studies, graduate with ease and ultimately become successful members of society.
        </LeadText>
        <blockquote>
          “I firmly believe that a lack of funding should not inhibit success, and access to education should not be dependent on wealth. As a society, we have to make an effort to invest in social justice. It starts with giving a fair start to every child and every young person regardless of their race, gender, and socioeconomic status. Inclusive development involves everyone and doesn’t leave anyone behind.” – Prof Thuli Madonsela
        </blockquote>
        <p>
          The #Action4Inclusion (#A4I) initiative was launched in 2020 as a collaborative endeavour by the Student Representative Council at Stellenbosch University (SU); the University’s inaugural director of the newly established Centre for Social Justice (former Law Trust Chair in Social Justice), Prof Thuli Madonsela; the former dean of the SU Faculty of Law, Prof Sonia Human; and other social justice ambassadors. This is one of several initiatives that fall under the University’s Bridge the Gap Annual Fund. This fund enables the community to support our students and close the gap between talent and financial need – something we all care about.
        </p>
        <blockquote css={tw`mb-5`}>
          “We are kick-starting our support for the #Action4Inclusion initiative with the 2023 Varsity Cup season, considering its commitment and strong history of changing lives. Maties Sport represents a strong footprint, both internally and externally, and through our various sport initiatives, we aim to make a difference in the lives of SU students.” – Ilhaam Groenewald, Stellenbosch University Chief Director: Maties Sport.
        </blockquote>
        <Button to="https://www.givengain.com/cc/action4inclusion/" size="md" css={tw`mb-10`}>Donate now via GivenGain</Button>
        <Grid lgGridCols={2}>
          <div> <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        ><iframe width="560" height="315" src="https://www.youtube.com/embed/G10ynE5An10?si=jJowA4bFTIHD1w2K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe></div></div>
          <div> <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        ><iframe width="560" height="315" src="https://www.youtube.com/embed/RcHODZ2wTlQ?si=PDTGKEbaM6P2T3QO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe></div></div>
        </Grid>
        <p>Join us for <SafeLink to="/blog/2023/09/action4-inclusion-social-justice-walk-dublin-30-september-2023/" css={tw`border-b-2 border-maroon hover:text-maroon`}>our latest event, on 30 September 2023 in Dublin, Ireland</SafeLink>!</p>
      </Section>
      <Section isShaded>
        <h2>How can you show your support?</h2>
        <Grid lgGridCols={2}>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <Img fluid={data.poster1.childImageSharp.fluid} />
            <div css={tw`mt-5 mb-5 text-center`}>
              <p css={tw`mb-5`}>The funds raised via #Action4Inclusion are used to support students who cannot register for the next academic year because of outstanding student fees, as well as graduates who are not able to access their academic records upon graduation, which hampers their capacity to contribute to a talented workforce. We believe that education is a right and not a privilege, and we are committed to creating a more inclusive environment where all students can thrive.</p>
              <SharingButtons
                title="#Action4Inclusion"
                url={`https://socialjustice.sun.ac.za${data.poster1.childImageSharp.fluid.src}`}
              />
            </div>
          </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <SafeLink to="https://www.givengain.com/cc/action4inclusion/">
              <Img fluid={data.poster2.childImageSharp.fluid} />
            </SafeLink>
            <div css={tw`mt-5 mb-5 text-center`}>
              <p css={tw`mb-5`}>All disbursements are subject to the availability of funds each year. Requests for assistance must be directed to the coordinator, Marna Lourens, and all benefiting students will be informed of the decisions concerned. Join us in our efforts to make education accessible to all. Your donation can change a student’s life. To support the #A4I Support Bursary Fund, please visit our online donation link at https://www.givengain.com/cc/action4inclusion/. Together, we can create a more inclusive future for all.</p>              
              <SharingButtons
                title="#Action4Inclusion"
                url={`https://socialjustice.sun.ac.za${data.poster2.childImageSharp.fluid.src}`}
              />
            </div>
          </div> 
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <Img fluid={data.poster3.childImageSharp.fluid} />
            <div css={tw`mt-5 mb-5 text-center`}>
              <p css={tw`mb-5`}>For a quick and easy way to donate, just scan the SnapCode above with your SnapScan app!</p>
              <SharingButtons
                title="#Action4Inclusion"
                url={`https://socialjustice.sun.ac.za${data.poster3.childImageSharp.fluid.src}`}
              />
            </div>
          </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <SafeLink to="https://www.givengain.com/cc/action4inclusion/">
              <Img fluid={data.poster4.childImageSharp.fluid} />
            </SafeLink>
            <div css={tw`mt-5 mb-5 text-center`}>
              <p css={tw`mb-5`}>Donate on the online giving platform, GivenGain. All contributions, no matter how small, are welcomed and will be greatly appreciated.</p>
              <SharingButtons
                title="#Action4Inclusion"
                url={`https://socialjustice.sun.ac.za${data.poster4.childImageSharp.fluid.src}`}
              />
            </div>
          </div> 
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
          <SafeLink to="https://matiesconnect.com/groups/55/feed">
          <Img fluid={data.poster5.childImageSharp.fluid} />
            </SafeLink>
            <div css={tw`mt-5 mb-5 text-center`}>
              <p css={tw`mb-5`}>If you are not yet registered on our Maties Connect platform — what are you waiting for? There you can share your expertise, see what your fellow Maties are up to, and advance your career. Click here to join</p>
              <SharingButtons
                title="#Action4Inclusion"
                url={`https://socialjustice.sun.ac.za${data.poster5.childImageSharp.fluid.src}`}
              />
            </div>
          </div>          
        </Grid>
      </Section>
      <Section>
        <h2>#Action4Inclusion Bursary Fund</h2>
        <p>The #A4I Bursary Fund is currently low, but with your support, we can make a significant difference in the lives of our students. Once the fund reaches R100 000, we will start the disbursement to provide financial assistance of up to R20 000 to undergraduate SU students to help pay for registration fees or graduation debt. In exceptional circumstances, the disbursement committee may consider increasing the graduation assistance amount up to a maximum of R50 000 per student, as determined by the committee. Bridge the Gap is not a full-cost study bursary, but a top-up or support fund for undergraduate students at SU in instances where all other avenues of funding have been exhausted. Undergraduate students must meet the minimum academic requirements for re-admission or for graduation each year. The fund will support returning SU students who struggle to pay all or part of the annual registration fees at the beginning of the academic year, as well as final-year students who have demonstrated a need for financial support towards graduation debt. A committee consisting of one social justice ambassador and two University representatives, comprising one member from the Bursaries and Loans Office and one member from the Centre for Social Justice, will oversee the distribution of funds. This committee will receive the latest SU student debt list by the end of November every year and will identify final-year SU students with debt on their accounts. Selected students will then be contacted and invited to apply with strong justification for support. Undergraduate students must demonstrate the need for financial assistance via a formal one-page signed justification if they want to benefit from the registration or end-of-year student debt relief support.</p>
        <Button to="https://www.givengain.com/cc/action4inclusion/" size="md">Donate now via GivenGain</Button>
      </Section>     
      <Section isShaded>
        <h2>Media coverage</h2>
        <p>For media enquiries, please contact Candice Egan (<SafeLink to="mailto:eganc@sun.ac.za" css={tw`border-b-2 border-maroon hover:text-maroon`}>eganc@sun.ac.za</SafeLink>, <SafeLink to="tel:0832353160" css={tw`border-b-2 border-maroon hover:text-maroon`}>0832353160</SafeLink>) or Thembalethu Seyisi (<SafeLink to="mailto:22301763@sun.ac.za" css={tw`border-b-2 border-maroon hover:text-maroon`}>22301763@sun.ac.za</SafeLink>, <SafeLink to="tel:0727853218" css={tw`border-b-2 border-maroon hover:text-maroon`}>0727853218</SafeLink>).</p>
        <h3 css={tw`mt-10`}>Videos</h3>
        <Grid>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/9Rd1yrALImY?si=F6AM0gDiJwvPT41-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/fT6oDPSdw3o?si=8Nd1bB3fZjrj-X3d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/4-ZHtYXWYNo?si=Qj_pra8P0eyjZwjn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/g6Otydt3ofU?si=OMuIFrfKajmxo0jd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/YRtVEwE3p6o?si=CNaU_Xy9BV9v_PLw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/4NW1RxXGwRQ?si=0ehRtZtb6u4nVEXp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/8o9HJ5ABtmg?si=GKZxU_S6Ncfq55CI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>         
         </div> </div>
          <div css={tw`p-5 bg-white rounded-lg shadow-md`}>
            <div
          css={[
            tw`mt-6 md:my-0 relative overflow-hidden w-full`,
            'padding-top: 56.25%;',
          ]}
        >
            <iframe width="560" height="315" src="https://www.youtube.com/embed/mX9p2QeGz64?si=nORY0bjq322V3I0B" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen css={tw`absolute top-0 left-0 w-full h-full border-0`}></iframe>
         </div> </div>                    
        </Grid>
        <h3 css={tw`mt-10`}>Articles</h3>
        <ul>         
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://mg.co.za/200YoungSouthAfricans/2023/thembalethu-seyisi/">Thembalethu Seyisi: 200 Young South Africans</SafeLink>, 23/06/2023</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.timeslive.co.za/news/south-africa/2023-04-20-madonsela-calls-on-lamola-to-recover-r51bn-used-by-nsfas-to-fund-unqualifying-students/">Madonsela calls on Lamola to recover R51bn used by NSFAS to fund unqualifying students</SafeLink>, 20/04/2023</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.stellenboschvisio.co.za/bid-now-own-a-portrait-of-thuli-madonsela-by-local-art-legend-shany-van-den-berg/">Bid now: Own a portrait of Thuli Madonsela by local art legend</SafeLink>, 20/09/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://briefly.co.za/people/women/138920-painting-thuli-madonsela-auctioned-raise-funds-scrap-student-debt-honour-60th-birthday/">Painting of Thuli Madonsela auctioned to raise funds to scrap student debt in honour of her 60th birthday</SafeLink>, 15/09/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.matiemedia.org/su-organisation-takes-steps-towards-eliminating-student-debt/">SU organisation takes steps towards eliminating student debt</SafeLink>, 27/06/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.bolanderlifestyle.co.za/news/walk-with-prof-thuli-eaf7cf2e-460c-44fe-8a83-a0eae8750e81">Walk with Prof Thuli</SafeLink>, 09/06/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.visitstellenbosch.org/join-the-action4inclusion-social-justice-walk-2022/">Join the #Action4Inclusion Social Justice Walk 2022</SafeLink>, 25/05/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://ewn.co.za/2022/04/05/stellenbosch-graduate-celebrates-by-raising-funds-for-students-in-debt">Stellenbosch graduate celebrates by raising funds for students in debt</SafeLink>, 05/04/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.capetownetc.com/news/stellenbosch-student-raises-money-to-help-students-out-of-debt-via-graduation/">Stellenbosch student raises money to help students out of debt through graduation</SafeLink>, 05/04/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.news24.com/news24/southafrica/news/feel-good-stellenbosch-university-student-raises-funds-to-help-students-in-debt-20220404">FEEL GOOD | Stellenbosch University student raises funds to help students in debt</SafeLink>, 04/04/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.iol.co.za/education/maties-student-pays-it-forward-by-skipping-grad-party-to-raise-funds-for-those-in-need-c06e1483-3d28-4091-93f7-27ad1bd21d93">Maties student pays it forward by skipping grad party to raise funds for those in need</SafeLink>, 01/04/2022</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.ebnewsdaily.co.za/2021/11/29/stellenbosch-university-hosts-16km-walk-to-pay-off-students-debt/">Stellenbosch University hosts 16km walk to pay off students’ debt</SafeLink>, 29/11/2021</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.groundup.org.za/article/stellenbosch-university-hosts-16km-walk-to-pay-off-students-debt/">Stellenbosch University hosts 16km walk to pay off students’ debt</SafeLink>, 29/11/2021</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.sowetanlive.co.za/news/south-africa/2021-08-18-plight-of-overburdened-student-moves-varsity-to-act/">Plight of overburdened student moves varsity to act</SafeLink>, 18/08/2021</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.universityworldnews.com/post.php?story=2020112906080046">Former public protector to climb a mountain for students</SafeLink>, 29/11/2020</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://ewn.co.za/2020/03/08/madonsela-launches-campaign-to-end-financial-exclusion-at-universities">Madonsela launches campaign to end financial exclusion at universities</SafeLink>, 08/03/2020</li>
          <li><SafeLink css={tw`border-b-2 border-maroon hover:text-maroon`} to="https://www.power987.co.za/news/thuli-madonsela-acts-against-students-financial-exclusion/">Thuli Madonsela acts against students’ financial exclusion</SafeLink>, 09/03/2020</li>
        </ul>        
      </Section>
    </Layout>
  );
};

export default ProjectsAction4Inclusion;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "projects-action4inclusion-hero.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    walkPoster: file(
      relativePath: {
        eq: "2021-11-action4inclusion-social-justice-walk-horizontal.jpg"
      }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster1: file(
      relativePath: { eq: "web_graphics_a4i-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster2: file(
      relativePath: { eq: "web_graphics_a4i4-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster3: file(
      relativePath: { eq: "web_graphics_a4i5-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster4: file(
      relativePath: { eq: "web_graphics_a4i6-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    poster5: file(
      relativePath: { eq: "web_graphics_a4i7-scaled.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
